// main: ../styles.less

[class^="icon-"], [class*=" icon-"] {
    .inline-block();
    background-image: url(../../img/icons.png);
    background-color: transparent;
    background-repeat: no-repeat;
}

// first row
.icon-palm {
    width: 35px;
    min-width: 35px;
    height: 56px;
    background-position: 0 0;
}
.icon-fist {
    width: 42px;
    min-width: 42px;
    height: 56px;
    background-position: -35px 0;
}
.icon-net-protect {
    width: 42px;
    min-width: 42px;
    height: 56px;
    background-position: -77px 0;
}
.icon-scales {
    width: 43px;
    height: 56px;
    background-position: -119px 0;
}
.icon-radar {
    width: 52px;
    min-width: 52px;
    height: 56px;
    background-position: -162px 0;
}
.icon-lamp {
    width: 49px;
    height: 56px;
    background-position: -214px 0;
}
.icon-serv {
    width: 48px;
    min-width: 48px;
    height: 56px;
    background-position: -217px -583px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .icon-palm,
    .icon-fist,
    .icon-net-protect,
    .icon-scales,
    .icon-radar,
    .icon-lamp {
        background-size: 408px 300px;
    }
    .icon-palm { background-position: -133px 0; }
    .icon-fist { background-position: -170px 0; }
    .icon-net-protect { background-position: -214px 0; }
    .icon-scales { background-position: -258px 0; }
    .icon-radar { background-position: -303px 0; }
    .icon-lamp { background-position: -357px 0; }
}

// second row
.icon-megaphone {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*0;
}
a:hover .icon-megaphone,
.active .icon-megaphone {
    background-position: -75px -56px-77px*0;
}
.icon-cart {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*1;
}
a:hover .icon-cart,
.active .icon-cart {
    background-position: -75px -56px-77px*1;
}
.icon-safe {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*2;
}
a:hover .icon-safe,
.active .icon-safe {
    background-position: -75px -56px-77px*2;
}
.icon-tv {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*3;
}
a:hover .icon-tv,
.active .icon-tv {
    background-position: -80px -56px-77px*3;
}
.icon-fabric {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*4;
}
a:hover .icon-fabric,
.active .icon-fabric {
    background-position: -75px -56px-77px*4;
}
.icon-providers {
    width: 75px;
    height: 77px;
    background-position: 0 -56px-77px*5;
}

a:hover .icon-providers,
.active .icon-providers {
    background-position: -75px -56px-77px*5;
}

.icon-games {
    width: 72px;
    height: 58px;
    margin-top: 19px;
    background-position: -3px -526px;
}
a:hover .icon-games,
.active .icon-games {
    background-position: -81px -526px;
}


// TODO: active states

// third row
.icon-atom {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*0;
}
.icon-splitter {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*1;
}
.icon-net-map {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*2;
}
.icon-route {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*3;
}
.icon-plug {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*4;
}
.icon-filter-locked {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*5;
}
.icon-filter-unlocked {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*6;
}
.icon-warp {
    width: 45px;
    height: 30px;
    background-position: -218px -133px-30px*7;
}

// fourth row
.icon-doc {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*0 -133px-86px*0;
}
.icon-shield {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*0 -133px-86px*1;
}
.icon-calendar {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*0 -133px-86px*2;
}
.icon-idea {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*1 -133px-86px*0;
}
.icon-connector {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*1 -133px-86px*1;
}
.icon-gift {
    width: 76px;
    height: 86px;
    background-position: -263px-76px*1 -133px-86px*2;
}

// separate
.icon-calc {
    width: 65px;
    height: 65px;
    background-position: -415px -133px;
}
.icon-bet {
    width: 64px;
    height: 77px;
    background-position: -415px-65px -133px;
}
.icon-play {
    width: 30px;
    height: 30px;
    background-position: -415px -198px;
}

.icon-check
{
    width: 14px;
    height: 15px;
    background: url(../../img/icons.png) no-repeat;
    vertical-align: middle;
    &.icon-check_green {
        background-position: -415px -242px ;

    }
    &.icon-check_grey {
        background-position: -415px -242px + -(16px) ;
    }
    &.icon-check_cross {
        background-position: -415px -273px ;
    }

}
.icon-check__sep {
    vertical-align: middle;
    display: inline-block;
}
