// main: ../styles.less
// mixins
.clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}
.inline-block-container {
    font-size: 0;
    line-height: 0;
    letter-spacing: 0;
}
.inline-block {
    display: 	-moz-inline-stack;
    display: 	inline-block !important;
    *display: 	inline;
    *zoom:      1;

    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    vertical-align: top;
}
.reset-component() {
    width: auto;
    margin:  0;
    padding: 0;

    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: inherit;

    align-items: flex-start;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    white-space: pre;
    user-select: none;
    outline: 0;
}
.unselectable() {
    -o-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.selectable() {
    -o-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    -webkit-user-select: initial;
    user-select: initial;
}
//
.placeholder(@rules) {
    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
}
