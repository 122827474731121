.stitches-footer-icon(@x: 0, @y: 0, @width: 0, @height: 0) {
  background-position: @x @y;
  width: @width;
  height: @height;
}

.footer-icon {
  background-image: url(../../img/footer-icons.png);
  background-repeat: no-repeat;
  display: block;

  &.footer-icon-facebook {
    .stitches-footer-icon(0, 0, 34px, 34px);
  }

  &.footer-icon-rss {
    .stitches-footer-icon(-34px, 0, 34px, 34px);
  }

  &.footer-icon-youtube {
    .stitches-footer-icon(0, -34px, 34px, 34px);
  }

  &.footer-icon-linkedin {
    background-image: url("../../img/icons-2020/linkedin.svg");
    background-size: contain;
    width: 34px;
    height: 34px;
  }
  &.footer-icon-twitter {
    background-image: url("../../img/icons-2020/twitter.png");
    background-size: contain;
    width: 34px;
    height: 34px;
  }
}
