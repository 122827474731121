
.panel-collapsable {
  &:not(.panel-collapsable-js) .glyphicon-chevron-down {
    display: none;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &.panel-collapsable-js {
    .panel-heading {
      cursor: pointer;
      color: inherit;
    }
    .glyphicon-chevron-down  {
      transition: 0.2s;
    }
    &.panel-collapsable-collapsed {
      .glyphicon-chevron-down {
        transform: rotate(-90deg);
      }
      .panel-body {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .panel-body {
      height: auto;
      overflow: hidden;
    }
  }
}


