.table-responsive {
    display:block;
    overflow-x:auto;
    border:none;
}

p > a {
    color: @midnight;
}
p > a:hover {
    color: @eggplant;
    text-decoration: underline !important;
}

.tooltiped {
    cursor: pointer;
    border-bottom: 1px dotted;
}

#waf-pricing {
    margin-bottom:50px;
    // padding-top:60px;
    margin-top:0;
    .plansTable {
        margin-top:0;

        tr td {
            width: 20%;
            // display: table-cell;
        }
    }

    .plansTable tr.plansTable-hs.js-group-title td:not(.js-param-col) {
        background-color:#fff;
        color: #46b9c3!important;
    }

    .priceTableText {
        font-size: 11px;
        margin-top: 10px;
    }

}

@media only screen and (max-width: 767px) {
    #waf-pricing {
        .plansTable {
            tr td {
                width: 50% !important;
                display: none;
            }
            tr td:nth-child(2),tr td:nth-child(1) {
                display: table-cell;
            }
        }
    }
}


.plansTable a.link {
    text-transform: none;
}

.plansTextTable tr td {
    border: none !important;
    text-align: left !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.plansTextTable tr td:first-child {
    // width: 37.5%;
}

.plansTextTable tr td:last-child {
    width: 62.5%;
}

.priceTableText {
    font-size: 13px;
    color: #a0aab4;
    font-weight: normal;
}

.w-text p {
    margin-bottom: 5px;
    font-size: 14px;
}

a.hover-lighten:hover {
    color: @alternativeColor;
}

.highlight {background-color:#ff0;color:red}

.row.stories_clients {
    min-height: 145px;
}

.aside.aside-full {
    width: 100%;
}


#subscribe_captcha {margin-top: 10px}

.tag-it.tag-it_x, .tag-it.tag-it_x {
    color:black;
    cursor: pointer
}

.tag-it.tag-it_x:hover:not(.selected) .tag-it__x {
    display:none;
}

.tag-it.tag-it.selected {
    border: 2px solid #49c1cb;
    padding: 9px;
    cursor: pointer;
}
.tag-it.tag-it_x.selected {
    border: 2px solid #49c1cb;
    padding: 9px 25px 9px 9px;
    cursor: default;
}

.tag-it.tag-it_x.selected .tag-it__x{
    background: url('../../img/icons.png') no-repeat -550px -343px;
    opacity: 1;
    cursor: pointer;
}

.js-filter-tag.selected {
    border-color: #ffa726;
}

.plansTable tr.plansTable-hs.js-group-title td.lighten {
    border-right: 1px solid #93d6dc !important;
    border-left: 1px solid #93d6dc !important;
}

.plansTable .plansTable-hs.js-group-title td:first-child {
    border-right: none !important;
}

.plansTable .plansTable-hs td.lighten:last-child {
    border-top: 1px solid #93d6dc !important;
    border-right: 1px solid #93d6dc !important;
    // color: #93d6dc !important;
}
.plansTable tr.plansTable-hs.js-group-title td:not(.js-param-col) {
    border-left: 1px solid #46b9c3 !important;
    border-right: 1px solid #46b9c3 !important;
}

.plansTable tr td.js-price-title {border-right:none}

.plansTable tr td.js-price-button:not(.lighten) {
    border:1px solid #ddd !important;
}

.plansTable tr td.js-price-button.lighten {
    border: 1px solid #fff;
}

.plansTable tr td.js-price-button.lighten {
    border: 1px solid #fff;
}

.plansTable tr td.js-price-button:nth-child(3) {
    border-right: 1px solid #ddd;
}

.plansTable tr td.js-price-button.lighten > a {
    display:none !important;
}

.plansTable tr td.js-price-button {
    border-top:1px solid #ddd !important;
}

.tooltip_o-bl {
    font-family: inherit;
    &.bottom{
        .tooltip-arrow {
            border-bottom-color: #209DC0;
        }
        .tooltip-inner {
            padding: 15px;
            color: #ffffff;
            font-size: 13px;
            text-align: initial;
            min-width: 260px;
            -webkit-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            background: #209DC0;
            border-radius: 3px;
        }
    }
}

.h3.title-plans {
    margin-top: 50px;
}
