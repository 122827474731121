
.icon2020 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &.logo-white {
    background-image: url("../../img/icons-2020/logo_white.svg");
    width: 120px;
    height: 22px;
  }

  &.slider {
    width: 100%;
    height: 100%;
    display: inline-block;
    flex: 0 0 50px;
    &.cdn {
      background-image: url("../../img/icons-2020/slider/cdn.svg");
    }
    &.ddos {
      background-image: url("../../img/icons-2020/slider/ddos.svg");
    }
    &.dns {
      background-image: url("../../img/icons-2020/slider/dns.svg");
    }
    &.provider {
      background-image: url("../../img/icons-2020/slider/provider.svg");
    }
    &.radar {
      background-image: url("../../img/icons-2020/slider/radar.svg");
    }
    &.antibot {
      background-image: url("../../img/icons-2020/slider/antibot.svg");
    }
    &.waf {
      background-image: url("../../img/icons-2020/slider/waf.svg");
    }
    &.partners {
      background-image: url("../../img/icons-2020/slider/partners.svg");
    }
    &.resellers {
      background-image: url("../../img/icons-2020/slider/resellers.svg");
    }
    &.mssp_isp {
      background-image: url("../../img/icons-2020/slider/mssp_isp.svg");
    }
  }

  &.tech {
    width: 36px;
    height: 36px;
    margin: 7px;
    &.https-open {
      background-image: url("../../img/icons-2020/technologies/https_open.svg");
    }
    &.https-closed {
      background-image: url("../../img/icons-2020/technologies/https_closed.svg");
    }
    &.dedicated-chanel {
      background-image: url("../../img/icons-2020/technologies/dedicated_chanel.svg");
    }
    &.balancing {
      background-image: url("../../img/icons-2020/technologies/balancing.svg");
    }
    &.dns {
      background-image: url("../../img/icons-2020/technologies/dns.svg");
    }
    &.bgp {
      background-image: url("../../img/icons-2020/technologies/bgp.svg");
    }
    &.monitoring {
      background-image: url("../../img/icons-2020/technologies/monitoring.svg");
    }
    &.connect {
      background-image: url("../../img/icons-2020/technologies/connect.svg");
    }
    &.api {
      background-image: url("../../img/icons-2020/technologies/api.svg");
    }
    &.f456 {
      background-image: url("../../img/icons-2020/technologies/456.svg");
    }
    &.waf {
      background-image: url("../../img/icons-2020/technologies/waf.svg");
    }
  }

  &.segment {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto;
    &.e-commerce {
      background-image: url("../../img/icons-2020/segments/e-commerce.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/e-commerce_active.svg");
      }
    }
    &.education {
      background-image: url("../../img/icons-2020/segments/education.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/education_active.svg");
      }
    }
    &.finance {
      background-image: url("../../img/icons-2020/segments/finance.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/finance_active.svg");
      }
    }
    &.game-services {
      background-image: url("../../img/icons-2020/segments/game-services.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/game-services_active.svg");
      }
    }
    &.hosting {
      background-image: url("../../img/icons-2020/segments/hosting.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/hosting_active.svg");
      }
    }
    &.mass-media {
      background-image: url("../../img/icons-2020/segments/mass-media.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/mass-media_active.svg");
      }
    }
    &.online-payment {
      background-image: url("../../img/icons-2020/segments/online-payment.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/online-payment_active.svg");
      }
    }
    &.providers {
      background-image: url("../../img/icons-2020/segments/providers.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/providers_active.svg");
      }
    }
    &.purchasing {
      background-image: url("../../img/icons-2020/segments/purchasing.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/purchasing_active.svg");
      }
    }
    &.tourism {
      background-image: url("../../img/icons-2020/segments/tourism.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/tourism_active.svg");
      }
    }
    &.tv-streaming {
      background-image: url("../../img/icons-2020/segments/tv-streaming.svg");
      &:hover {
        background-image: url("../../img/icons-2020/segments/tv-streaming_active.svg");
      }
    }
  }
}

.segments {
  .active {
    .icon2020 {
      &.segment {
        &.e-commerce {
          background-image: url("../../img/icons-2020/segments/e-commerce_active.svg");
        }
        &.education {
          background-image: url("../../img/icons-2020/segments/education_active.svg");
        }
        &.finance {
          background-image: url("../../img/icons-2020/segments/finance_active.svg");
        }
        &.game-services {
          background-image: url("../../img/icons-2020/segments/game-services_active.svg");
        }
        &.hosting {
          background-image: url("../../img/icons-2020/segments/hosting_active.svg");
        }
        &.mass-media {
          background-image: url("../../img/icons-2020/segments/mass-media_active.svg");
        }
        &.online-payment {
          background-image: url("../../img/icons-2020/segments/online-payment_active.svg");
        }
        &.providers {
          background-image: url("../../img/icons-2020/segments/providers_active.svg");
        }
        &.purchasing {
          background-image: url("../../img/icons-2020/segments/purchasing_active.svg");
        }
        &.tourism {
          background-image: url("../../img/icons-2020/segments/tourism_active.svg");
        }
        &.tv-streaming {
          background-image: url("../../img/icons-2020/segments/tv-streaming_active.svg");
        }
      }
    }
  }
}
